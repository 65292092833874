import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

const API_URL = process.env.REACT_APP_API_URL;

const ImageManagement = () => {
  const { categoryId } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [newImageMessage, setNewImageMessage] = useState('');
  const [newImageUrl, setNewImageUrl] = useState('');

  const fetchImages = useCallback(async () => {
    if (!hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/image_data/list/${categoryId}/paginated`, {
        params: {
          page,
          limit: 10,
        },
      });
      const { images: newImages } = response.data.data;
      setImages((prevImages) => {
        const updatedImages = [...prevImages, ...newImages];
        // Remove duplicates by filtering based on unique id
        const uniqueImages = Array.from(new Set(updatedImages.map(image => image.id)))
          .map(id => updatedImages.find(image => image.id === id));
        return uniqueImages;
      });
      setHasMore(newImages.length > 0);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false);
    }
  }, [categoryId, page, hasMore]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleCreateImage = async () => {
    try {
      const response = await axios.post(`${API_URL}/image_data/create-manual`, {
        message: newImageMessage,
        url: newImageUrl,
        category_id: categoryId,
      });
  
      const newImage = response.data.data;
  
      setImages((prevImages) => {
        // Add new image to the beginning of the list
        const updatedImages = [newImage, ...prevImages];
  
        // Remove duplicates
        const uniqueImages = Array.from(new Set(updatedImages.map(image => image.id)))
          .map(id => updatedImages.find(image => image.id === id));
          
        // Sort images in descending order, e.g., by ID
        return uniqueImages.sort((a, b) => b.id - a.id);
      });
  
      // Clear form fields
      setNewImageMessage('');
      setNewImageUrl('');
    } catch (error) {
      console.error('Error creating image:', error);
    }
  };

  const handleDeleteImage = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this image?');
    if (confirmed) {
      try {
        await axios.delete(`${API_URL}/image_data/delete/${id}`);
        setImages((prevImages) => prevImages.filter((image) => image.id !== id));
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      <h2 className="title is-3">Image Management</h2>
      <div className="field">
        <div className="control">
          <input
            className="input is-info"
            type="text"
            placeholder="Enter image message"
            value={newImageMessage}
            onChange={(e) => setNewImageMessage(e.target.value)}
            style={{ marginBottom: '1rem' }}
          />
        </div>
        <div className="control">
          <input
            className="input is-info"
            type="text"
            placeholder="Enter image URL"
            value={newImageUrl}
            onChange={(e) => setNewImageUrl(e.target.value)}
            style={{ marginBottom: '1rem' }}
          />
        </div>
        <div className="control">
          <Button onClick={handleCreateImage} variant="contained" color="primary">
            Create Image
          </Button>
        </div>
      </div>
      <div className="columns is-multiline">
        {images.map((image) => (
          <div className="column is-one-third" key={image.id}>
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={image.url} alt={image.message} />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{image.message}</p>
                    <p className="subtitle is-6">ID: {image.id}</p>
                  </div>
                </div>
              </div>
              <footer className="card-footer">
                <button
                  onClick={() => handleDeleteImage(image.id)}
                  className="card-footer-item has-text-danger"
                  style={{ cursor: 'pointer' }}
                >
                  <DeleteIcon style={{ marginRight: '0.5rem' }} />
                  Delete
                </button>
              </footer>
            </div>
          </div>
        ))}
      </div>
      {loading && <div className="has-text-centered"><CircularProgress /></div>}
      {hasMore && !loading && (
        <div className="has-text-centered">
          <Button onClick={handleLoadMore} variant="contained" color="primary">
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageManagement;
