import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

const API_URL = process.env.REACT_APP_API_URL;

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/category/list`);
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCreateCategory = async () => {
    try {
      await axios.post(`${API_URL}/category/create`, { name: newCategoryName });
      setNewCategoryName('');
      fetchCategories();
    } catch (error) {
      console.error('Error creating category:', error);
    }
  };

  const handleDeleteCategory = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (confirmed) {
      try {
        await axios.delete(`${API_URL}/category/delete/${id}`);
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const handleCategoryClick = (id) => {
    navigate(`/images/${id}`);
  };

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container" style={{ padding: '1rem' }}>
      <h2 className="title is-3">Category Management</h2>

      <div className="field is-grouped">
        <div className="control is-expanded">
          <input
            className="input is-primary"
            type="text"
            placeholder="New Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </div>
        <div className="control">
          <Button onClick={handleCreateCategory} variant="contained" color="primary">
            Create Category
          </Button>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <input
            className="input is-info"
            type="text"
            placeholder="Search Categories"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="box">
        <ul>
          {filteredCategories.map((category) => (
            <li className="list-item" key={category.id}>
              <div className="columns is-mobile is-vcentered">
                <div className="column is-narrow">
                  <span
                    className="has-text-weight-semibold"
                    onClick={() => handleCategoryClick(category.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {category.name}
                  </span>
                </div>
                <div className="column is-narrow">
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(category.id);
                    }}
                    style={{ color: 'red' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoryManagement;
