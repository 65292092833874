import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import 'bulma/css/bulma.min.css';

const StyledContainer = styled(Container)({
  padding: '2rem',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
});

const StyledCard = styled(Card)({
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  marginBottom: '2rem',
});

const StyledCardContent = styled(CardContent)({
  padding: '2rem',
});

const Title = styled(Typography)({
  marginBottom: '1rem',
  color: '#333333',
});

const SubTitle = styled(Typography)({
  marginTop: '1.5rem',
  marginBottom: '0.5rem',
  color: '#007BFF',
});

const Paragraph = styled(Typography)({
  marginBottom: '1rem',
  lineHeight: '1.6',
  '& strong': {
    color: '#000000', // Set color for strong text to black
  },
});

const PrivacyPolicy = () => {
  return (
    <StyledContainer maxWidth="md">
      <StyledCard>
        <StyledCardContent>
          <Title variant="h4" component="h1">
            Privacy Policy for Qu Quotes - Daily Motivation
          </Title>
          <Paragraph variant="body1">
            Thank you for choosing Qu Quotes - Daily Motivation. Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our mobile application ("the App").
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            1. Information We Collect
          </SubTitle>
          <Paragraph variant="body1">
            We may collect the following types of information when you use our App:
          </Paragraph>
          <Paragraph variant="body1">
            <strong>Personal Information:</strong> We do not collect any personally identifiable information such as name, email address, or contact details unless explicitly provided by you through the app for specific features.
          </Paragraph>
          <Paragraph variant="body1">
            <strong>Usage Data:</strong> We may collect information about your interactions with the App, such as app usage frequency, selected themes, and preferences. This data is used to improve your experience and app performance.
          </Paragraph>
          <Paragraph variant="body1">
            <strong>Device Information:</strong> We may collect information about your device, such as device model, operating system version, and unique device identifiers.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            2. How We Use Your Information
          </SubTitle>
          <Paragraph variant="body1">
            We use the information we collect to:
          </Paragraph>
          <Paragraph variant="body1">
            - Provide and improve the App’s functionality.
          </Paragraph>
          <Paragraph variant="body1">
            - Personalize your experience, such as suggesting quotes based on your preferences.
          </Paragraph>
          <Paragraph variant="body1">
            - Send notifications if you have opted in for daily quotes or reminders.
          </Paragraph>
          <Paragraph variant="body1">
            - Ensure the App operates efficiently and effectively.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            3. Sharing of Information
          </SubTitle>
          <Paragraph variant="body1">
            We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share your information in the following circumstances:
          </Paragraph>
          <Paragraph variant="body1">
            <strong>Service Providers:</strong> We may share your information with third-party service providers that help us operate and maintain the App (e.g., analytics services). These providers are obligated to keep your information confidential and secure.
          </Paragraph>
          <Paragraph variant="body1">
            <strong>Legal Compliance:</strong> We may disclose your information if required by law or if we believe such action is necessary to comply with legal obligations, protect our rights, or ensure the safety of users.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            4. Security of Your Information
          </SubTitle>
          <Paragraph variant="body1">
            We take reasonable steps to protect your information from unauthorized access, use, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            5. Your Choices
          </SubTitle>
          <Paragraph variant="body1">
            You can choose to:
          </Paragraph>
          <Paragraph variant="body1">
            - <strong>Opt-Out of Notifications:</strong> You can disable notifications through your device settings if you no longer wish to receive daily quotes or reminders.
          </Paragraph>
          <Paragraph variant="body1">
            - <strong>Limit Data Collection:</strong> You can limit data collection by adjusting your device settings to restrict access to certain information (e.g., location services).
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            6. Third-Party Links
          </SubTitle>
          <Paragraph variant="body1">
            Our App may include links to third-party websites or services. These third-party sites have separate and independent privacy policies, and we are not responsible for their content or privacy practices.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            7. Changes to This Privacy Policy
          </SubTitle>
          <Paragraph variant="body1">
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by updating the "Effective Date" at the top of this policy. We encourage you to review this policy periodically.
          </Paragraph>
          <SubTitle variant="h6" component="h2">
            8. Contact Us
          </SubTitle>
          <Paragraph variant="body1">
            If you have any questions or concerns about this Privacy Policy or our practices, please contact us at <a href="mailto:surya.appkey@gmail.com" style={{ color: '#007BFF' }}>surya.appkey@gmail.com</a>.
          </Paragraph>
        </StyledCardContent>
      </StyledCard>
    </StyledContainer>
  );
};

export default PrivacyPolicy;
